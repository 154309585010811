/* eslint-disable  @typescript-eslint/no-explicit-any */

/**
 * Function to deeply compare two objects or values to check for equality.
 * @param obj1
 * @param obj2
 * @returns boolean
 */
export function deepEqual(obj1: any, obj2: any): boolean {
  // First, check if the two objects are strictly equal (same reference or primitive value)
  if (obj1 === obj2) {
    return true;
  }

  // If either value is not an object or is null, return false
  // This handles cases where one value is an object and the other isn't, or one is null
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If the objects have a different number of keys, they cannot be equal
  if (keys1.length !== keys2.length) {
    return false; // Different number of keys means the objects are not equal
  }

  // Iterate through the keys of the first object
  for (const key of keys1) {
    // Check if the second object has the same key and if the corresponding values are deeply equal
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
