import { inject, makeEnvironmentProviders } from '@angular/core';
import { provideAuthenticationConfig } from './authentication-config.token';
import { provideAuthenticationInterceptor } from './authentication.interceptor';
import { LoggedInSignalToken } from '@cca-infra/core';
import { AuthenticationConfig } from '@cca-environment';
import { AuthenticationStore } from './state';

export function provideAuthentication(config: AuthenticationConfig) {
  return makeEnvironmentProviders([
    [
      provideAuthenticationInterceptor(),
      provideAuthenticationConfig(config),
      {
        provide: LoggedInSignalToken,
        useFactory: () => inject(AuthenticationStore).authenticated,
      },
    ],
  ]);
}
