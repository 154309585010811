import { inject } from '@angular/core';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { EMPTY, exhaustMap, pipe, switchMap } from 'rxjs';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { Snackbar } from '@cca-common/snackbar';
import {
  AuthenticationService,
  toAuthModel,
} from '@cca-infra/user-management/v1';
import { AuthenticationStoreState } from '../authentication.state';
import { UserAuthenticationRequestModel } from '../../model';
import { createDeadLockWarning, progressTimeOut } from '../progress-timeout';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withLogin<_>() {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
      methods: type<{ _navigateAfterLogin(): void }>(),
    },
    withMethods((store) => {
      const snackbar = inject(Snackbar);
      const authService = inject(AuthenticationService);

      return {
        login: rxMethod<UserAuthenticationRequestModel>(
          pipe(
            exhaustMap((request) => {
              // indicator for logging in
              patchState(store, () => ({
                authenticationInProgress: true,
                error: null,
              }));

              // request a login
              return authService.login(request.username, request.password).pipe(
                tapResponse(
                  (user) => {
                    // patch our authentication state
                    patchState(store, () => ({
                      _projectedUser: null,
                      _realUser: toAuthModel(user),
                      authenticationInProgress: false,
                      error: null,
                    }));
                  },
                  (error) => {
                    // on error, patch the state that authentication failed
                    patchState(store, () => ({
                      _projectedUser: null,
                      _realUser: null,
                      authenticationInProgress: false,
                      error: error,
                    }));

                    snackbar.openError(
                      t('authentication.snackbar.authenticationError'),
                    );
                  },
                ),
              );
            }),
          ),
        ),
        loginSso: rxMethod<{ accessToken: string }>(
          pipe(
            exhaustMap((request) => {
              // indicator for logging in
              patchState(store, () => ({
                authenticationInProgress: true,
                error: null,
              }));

              // request a login
              return authService.loginSso(request.accessToken).pipe(
                tapResponse(
                  (user) => {
                    // patch our authentication state
                    patchState(store, () => ({
                      _projectedUser: null,
                      _realUser: toAuthModel(user),
                      authenticationInProgress: false,
                      error: null,
                    }));

                    // navigation after login
                    store._navigateAfterLogin();
                  },
                  (error) => {
                    // on error, patch the state that authentication failed
                    patchState(store, () => ({
                      _projectedUser: null,
                      _realUser: null,
                      authenticationInProgress: false,
                      error: error,
                    }));

                    snackbar.openError(
                      t('authentication.snackbar.authenticationError'),
                    );
                  },
                ),
              );
            }),
          ),
        ),
        _clearLogin: rxMethod<boolean>(
          pipe(
            switchMap((authenticationInProgress) => {
              // using a filter will not cancel pending progressTimeOut, checking it here and returning EMPTY will cancel pending timeOut
              if (!authenticationInProgress) {
                return EMPTY;
              }

              return progressTimeOut(() => {
                // Log a warning message indicating a possible deadlock with the 'authentication' process.
                console.warn(createDeadLockWarning('authentication'));

                // Update the state to indicate that authentication is no longer in progress,
                // resolving the deadlock condition.
                patchState(store, () => ({
                  authenticationInProgress: false,
                }));
              });
            }),
          ),
        ),
      };
    }),
    withHooks((store) => {
      return {
        onInit: () => {
          store._clearLogin(store.authenticationInProgress);
        },
      };
    }),
  );
}
