import {
  getState,
  patchState,
  signalStoreFeature,
  type,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { AuthenticationStoreState } from '../authentication.state';
import { inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  pipe,
  sampleTime,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { WINDOW } from '@cca-environment';
import { DOCUMENT } from '@angular/common';
import { deepEqual } from '../deep-equal';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withSynchronization<_>(storageKey: string) {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
    },
    withMethods((store) => {
      const document = inject(DOCUMENT);

      // Observable for visibility change event
      const visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(
        // Start with the current visibility state
        startWith(document.visibilityState),

        // Map to true if visible, false otherwise
        map(() => document.visibilityState === 'visible'),

        // Only emit when the visibility state actually changes
        distinctUntilChanged(),
      );

      return {
        _synchronizeFromStorageEvent: rxMethod<StorageEvent>(
          pipe(
            // First, filter events that match the desired storage key.
            filter((event) => event.key === storageKey),

            // Filter out events where the newValue is null (ignore deletions).
            filter((event) => event.newValue !== null),

            // Use sampleTime to throttle events to once every 300ms, reducing the frequency of event processing
            // and preventing rapid event triggers from overwhelming the system.
            sampleTime(300),

            // Switch to handling visibility change events when a storage event occurs.
            // This ensures that the storage event is only processed when the tab/window becomes visible.
            switchMap((event) => {
              return visibilityChange$.pipe(
                // Filter to only act when the visibility state is 'visible' (truthy).
                filter((visibility) => !!visibility),

                // Map the visibility change event back to the original storage event.
                map(() => event),

                // Start with the original storage event to ensure it's processed even if visibility is already 'visible'.
                startWith(event),
              );
            }),

            // Tap into the observable to attempt to parse and patch the state.
            tap((event) => {
              try {
                // Parse the state from the event's newValue.
                const parsedState = JSON.parse(event.newValue as string);

                // Use custom deep equality check for state comparison to avoid unnecessary state updates.
                if (!deepEqual(getState(store), parsedState)) {
                  patchState(store, () => parsedState);
                }
              } catch (err) {
                // Log any JSON parsing errors that may occur.
                console.error(`Could not parse and patch state`, err);
              }
            }),
          ),
        ),
      };
    }),
    withHooks((store) => {
      const window = inject(WINDOW);

      return {
        onInit: () => {
          if (window) {
            store._synchronizeFromStorageEvent(
              fromEvent<StorageEvent>(window, 'storage'),
            );
          }
        },
      };
    }),
  );
}
