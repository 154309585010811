import {
  signalStoreFeature,
  type,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { AuthenticationStoreState } from '../authentication.state';
import { WINDOW } from '@cca-environment';
import { inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { DerivedAuthViewModel } from '@cca-infra/user-management/v1';
import { pipe, tap } from 'rxjs';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withSmartlook<_>() {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
    },
    withMethods(() => {
      const window = inject(WINDOW);
      return {
        applySmartLook: rxMethod<DerivedAuthViewModel | null>(
          pipe(
            tap((user) => {
              // apply smartlook identification
              if (user) {
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                (window as any)?.smartlook(
                  'identify',
                  user.userId,
                  user.smartlookIdentity,
                );
              }

              // remove when user becomes null
              else {
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                (window as any)?.smartlook('anonymize');
              }
            }),
          ),
        ),
      };
    }),
    withHooks((store) => {
      return {
        onInit: () => {
          store.applySmartLook(store._realUser);
        },
      };
    }),
  );
}
