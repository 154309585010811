import { withDevTracking, withRemembering } from '@cca-common/core';
import {
  patchState,
  signalStore,
  signalStoreFeature,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  AuthenticationStoreState,
  initialAuthenticationState,
} from './authentication.state';
import { computed } from '@angular/core';
import { isAuthViewModel, toAuthModel } from '@cca-infra/user-management/v1';
import {
  withLocalization,
  withLogin,
  withLogout,
  withProjection,
  withRefresh,
  withSmartlook,
  withSynchronization,
  withTermsOfuse,
} from './features';
import { withNavigation } from './features/navigation';
import { pipe, tap } from 'rxjs';
import { rxMethod } from '@ngrx/signals/rxjs-interop';

export const AUTHENTICATION_STORAGE_KEY = 'AUTHENTICATION_STORE';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function withAuthenticationState<_>() {
  return signalStoreFeature(
    // initial state
    withState<AuthenticationStoreState>(initialAuthenticationState()),

    // computed properties
    withComputed((store) => {
      return {
        authenticated: computed(() => !!store._realUser()),
        realUser: computed(() => {
          const realUser = store._realUser();
          return isAuthViewModel(realUser) ? toAuthModel(realUser) : realUser;
        }),
        projectedUser: computed(() => {
          const projectedUser = store._projectedUser();
          return isAuthViewModel(projectedUser)
            ? toAuthModel(projectedUser)
            : projectedUser;
        }),
      };
    }),
    withComputed((store) => {
      return {
        user: computed(() => {
          const realUser = store.realUser();
          const projectedUser = store.projectedUser();

          return projectedUser ?? realUser;
        }),
      };
    }),
    withComputed((store) => {
      return {
        realPermissions: computed(() => store.realUser()?.permissions ?? {}),
        permissions: computed(() => store.user()?.permissions ?? {}),
      };
    }),

    withMethods((store) => ({
      clearProgressIndicators: rxMethod<void>(
        pipe(
          tap(() =>
            patchState(store, () => ({
              authenticationInProgress: false,
              refreshingTokenInProgress: false,
            })),
          ),
        ),
      ),
    })),
  );
}

export const AuthenticationStore = signalStore(
  {
    providedIn: 'root',
  },
  // signalStore has a upper limit of 15 functions, by splitting of the initial state/computed into a seperate slice
  // we are able to fit everything in the store
  withAuthenticationState(),

  // provides navigation methods
  withNavigation(),

  // login, logout functions
  withLogin(),
  withLogout(),

  // projection and refresh
  withProjection(),
  withRefresh(),

  // localization for syncing locale/language
  withLocalization(),

  // smartlook is used for tracking how our users use the application, this feature adds some meta data about what kind of user it is
  // eg is this a internal/carrier/shipper etc
  withSmartlook(),

  // checks if the user accepted the terms of use
  withTermsOfuse(),

  // saving and synchronization of data
  withRemembering(AUTHENTICATION_STORAGE_KEY),
  withSynchronization(AUTHENTICATION_STORAGE_KEY),

  // integration into dev menu
  withDevTracking('AuthenticationStore'),
);
