import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
  withHooks,
} from '@ngrx/signals';
import { AuthenticationStoreState } from '../authentication.state';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { inject } from '@angular/core';
import {
  AuthenticationService,
  toAuthModel,
} from '@cca-infra/user-management/v1';
import { EMPTY, exhaustMap, pipe, race, switchMap } from 'rxjs';
import { authenticationTimeOutInMs } from '../../constants';
import { deepEqual } from '../deep-equal';
import { createDeadLockWarning, progressTimeOut } from '../progress-timeout';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withRefresh<_>() {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
      methods: type<{
        _navigateAfterLogout: () => void;
      }>(),
    },
    withMethods((store) => {
      const authService = inject(AuthenticationService);

      const refreshFn = rxMethod<void>(
        pipe(
          exhaustMap(() => {
            const userId = store._realUser()?.id;
            // if there is no user data we cannot refresh
            if (!userId) {
              return EMPTY;
            }

            if (store.refreshingTokenInProgress()) {
              return EMPTY;
            }

            // set refresh indicator
            patchState(store, () => ({
              refreshingTokenInProgress: true,
              error: null,
            }));

            return race(
              // time out the attempt to refresh if it takes too long
              progressTimeOut(() => {
                patchState(store, () => ({
                  refreshingTokenInProgress: false,
                  _realUser: null,
                  _projectedUser: null,
                  error: `Timeout while requesting a new accessToken, request took over ${authenticationTimeOutInMs} ms`,
                }));
              }),

              // request a refresh on authentication data
              authService.refreshAccessToken(userId).pipe(
                tapResponse(
                  (user) => {
                    const userAuthModel = toAuthModel(user);
                    // if userData changed patch userData
                    if (!deepEqual(store._realUser(), userAuthModel)) {
                      patchState(store, () => ({
                        refreshingTokenInProgress: false,
                        _realUser: userAuthModel,
                      }));
                    }
                    // else just reset refreshingTokenInProgress
                    else {
                      patchState(store, () => ({
                        refreshingTokenInProgress: false,
                      }));
                    }
                  },
                  () => {
                    patchState(store, () => ({
                      refreshingTokenInProgress: false,
                      _realUser: null,
                      _projectedUser: null,
                      error: `A error has occurred while refreshing`,
                    }));
                  },
                ),
              ),
            );
          }),
        ),
      );

      return {
        refresh: refreshFn,
        _clearRefresh: rxMethod<boolean>(
          pipe(
            switchMap((refreshInProgress) => {
              // using a filter will not cancel pending progressTimeOut, checking it here and returning EMPTY will cancel pending timeOut
              if (!refreshInProgress) {
                return EMPTY;
              }

              return progressTimeOut(() => {
                // Log a warning message indicating a possible deadlock with the 'refresh' process.
                console.warn(createDeadLockWarning('refresh'));

                // Update the state to indicate that refreshing is no longer in progress,
                // resolving the deadlock condition.
                patchState(store, () => ({
                  refreshingTokenInProgress: false,
                }));

                // After handling a possible deadlock, attempt to refresh again as the data might be out of sync.
                console.log('refresh trigger?');
                refreshFn();
              });
            }),
          ),
        ),
      };
    }),
    withHooks((store) => {
      return {
        onInit: () => {
          store._clearRefresh(store.refreshingTokenInProgress);
        },
      };
    }),
  );
}
